<div class="ion-text-center">
  @if (image()) {
    <img alt="" [src]="src" />
  }
    
  @if (title) {
    <h2>{{ title }}</h2>
  }
  
  @if (firstContent) {
    <p>
      {{ firstContent }}
      @if (link()) {
        <a target="_blank" [href]="link()">{{ link() }}</a>
      }
    </p>
  }
  
  @if (secondContent) {
    <p>{{ secondContent }}</p>
  }
</div>
